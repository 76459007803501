import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../context/UserContext";
import {
  askQuestionToMoodee,
  deleteChat,
  deleteChatsAfterDate,
  deleteConversation,
  getAllConversationsByUser,
  getConversationHistory,
  postFirstMessage,
  startNewChat,
} from "../lib/askMoodee";
import { getAllFocusesByUser } from "../lib/focus";
import { useDebounce } from "../lib/useDebounce";
import { typeWriter } from "../lib/utils";
import { Chat, Conversation, Focus } from "../types";
import ChatHeader from "./chat/ChatHeader";
import ChatInput from "./chat/ChatInput";
import ChatMessages from "./chat/ChatMessages";
import ChatSidebar from "./chat/ChatSidebar";
import NewChatForm from "./chat/NewChatForm";

const ChatPage: React.FC = () => {
  const { user, socialMedias } = useUser();
  const [searchConversation, setSearchConversation] = useState<string>("");
  const [searchChat, setSearchChat] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [conversationsLoading, setConversationsLoading] =
    useState<boolean>(false);
  const [answerLoading, setAnswerLoading] = useState<boolean>(false);
  const [answerFinished, setAnswerFinished] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [focuses, setFocuses] = useState<Focus[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<
    Conversation[]
  >([]);

  const [selectedFocus, setSelectedFocus] = useState<string>();
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<string[]>([]);
  const [currentConversationChats, setCurrentConversationChats] = useState<
    Chat[] | null
  >(null);
  const [modifiedChatId, setModifiedChatId] = useState<string | null>(null);
  const [chatContent, setChatContent] = useState<string>("");
  const [moodeeWaitMessage, setMoodeeWaitMessage] = useState(
    "Analyse du prompt...",
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const debouncedSearchConversation = useDebounce(searchConversation, 500);

  // Effect for answer loading message updates
  useEffect(() => {
    if (answerLoading) {
      setMoodeeWaitMessage("Analyse du prompt...");
      setTimeout(() => {
        setMoodeeWaitMessage("Génération de la réponse...");
      }, 10000);
    }
  }, [answerLoading]);

  // Effect for conversation filtering
  useEffect(() => {
    if (debouncedSearchConversation.trim() === "") {
      setFilteredConversations(conversations);
    } else {
      const filtered = conversations.filter((conversation) =>
        conversation.focus_names.some((focus) =>
          focus
            .toLowerCase()
            .includes(debouncedSearchConversation.toLowerCase()),
        ),
      );
      setFilteredConversations(filtered);
    }
  }, [debouncedSearchConversation, conversations]);

  // Effect for loading focuses
  useEffect(() => {
    const loadFocuses = async () => {
      try {
        const data = await getAllFocusesByUser();
        setFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setLoading(false);
      }
    };
    loadFocuses();
  }, []);

  // Effect for loading conversations
  useEffect(() => {
    const loadConversations = async () => {
      try {
        setConversationsLoading(true);
        const data = await getAllConversationsByUser();
        setConversations(data);
        setConversationsLoading(false);
      } catch (err: any) {
        setError(err.message || "Failed to fetch conversations.");
      }
    };
    loadConversations();
  }, []);

  // Effect for scrolling to bottom on new messages
  useEffect(() => {
    if (currentConversationChats) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [currentConversationChats]);

  const handleStartChat = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedFocus || selectedSocialMedia.length === 0) {
      setError("Please select a focus and a social media platform.");
      return;
    }

    try {
      setLoading(true);
      const conversationData = {
        focus_ids: [selectedFocus],
        social_media_ids: selectedSocialMedia,
      };

      const newConversation = await startNewChat(conversationData);
      const updatedConversations = await getAllConversationsByUser();
      setConversations(updatedConversations);

      const firstMessage = await postFirstMessage(
        newConversation.conversation_id,
      );
      setCurrentConversationChats([firstMessage]);

      const holderId = `txt-holder-${firstMessage.chat_id}`;
      setTimeout(() => {
        const holderElement = document.getElementById(holderId);
        if (holderElement) {
          holderElement.innerHTML = "";
          typeWriter([firstMessage.content], 0, 10, holderElement);
        }
      }, 100);

      setLoading(false);
      setSelectedFocus("");
      setSelectedSocialMedia([]);
    } catch (error) {
      setError("Failed to start a new conversation.");
      setLoading(false);
      setAnswerLoading(false);
    }
  };

  const handleConversationSelect = async (conversationId: string) => {
    try {
      const data = await getConversationHistory(conversationId);
      setCurrentConversationChats(data);
    } catch (err: any) {
      setError(err.message || "Failed to fetch conversation.");
    }
  };

  const handlePostMessageOnChat = (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    const newMessage = {
      chat_id: crypto.randomUUID(),
      conversation_id: currentConversationChats[0].conversation_id,
      content: chatContent,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      favorite: false,
      author: "User",
    };

    setCurrentConversationChats((prev) =>
      prev ? [...prev, newMessage] : [newMessage],
    );
    setChatContent("");
  };

  const handleGetMoodeeAnswer = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    try {
      setAnswerLoading(true);
      setAnswerFinished(false);

      const newChat = {
        chat_id: crypto.randomUUID(),
        conversation_id: currentConversationChats[0].conversation_id,
        content: "",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        author: "Moodee",
        favorite: false,
      };

      setCurrentConversationChats((prev) =>
        prev ? [...prev, newChat] : [newChat],
      );

      let firstChunkReceived = false;

      const handleChunk = (chunk: string) => {
        if (!firstChunkReceived) {
          firstChunkReceived = true;
          setAnswerLoading(false);
        }

        setCurrentConversationChats((prev) =>
          prev
            ? prev.map((chat) =>
                chat.chat_id === newChat.chat_id
                  ? { ...chat, content: chat.content + chunk }
                  : chat,
              )
            : [newChat],
        );
      };

      await askQuestionToMoodee(
        currentConversationChats[0].conversation_id,
        chatContent,
        handleChunk,
      );

      setAnswerLoading(false);
      setAnswerFinished(true);
    } catch (err: any) {
      setError("Failed to fetch the latest message.");
      setAnswerLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (modifiedChatId) {
      handleDeleteChatsAfter(modifiedChatId);
    }
    handlePostMessageOnChat(e);
    handleGetMoodeeAnswer(e);
    setModifiedChatId(null);
  };

  const handleDeleteConversation = async () => {
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    try {
      await deleteConversation(currentConversationChats[0].conversation_id);
      setConversations((prev) =>
        prev.filter(
          (conv) =>
            conv.conversation_id !==
            currentConversationChats[0].conversation_id,
        ),
      );
      setCurrentConversationChats(null);
      setIsModalOpen(false);
    } catch (err: any) {
      setError("Failed to delete conversation.");
    }
  };

  const handleDeleteChat = async (chatId: string) => {
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    try {
      await deleteChat(chatId);
      setCurrentConversationChats((prev) =>
        prev ? prev.filter((chat) => chat.chat_id !== chatId) : null,
      );
    } catch (err: any) {
      setError("Failed to delete chat.");
    }
  };

  const handleDeleteChatsAfter = async (chatId: string) => {
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    setCurrentConversationChats((prev) => {
      if (!prev) return null;

      const modifiedChat = prev.find((chat) => chat.chat_id === chatId);
      if (!modifiedChat) {
        setError("Chat not found in the current conversation.");
        return prev;
      }

      const modifiedDate = new Date(modifiedChat.created_at);
      return prev
        .filter((chat) => {
          const chatDate = new Date(chat.created_at);
          return chatDate <= modifiedDate;
        })
        .filter((chat) => chat.chat_id !== chatId);
    });

    await deleteChatsAfterDate(chatId);
  };

  const selectedConversation = conversations.find(
    (conv) =>
      conv.conversation_id === currentConversationChats?.[0]?.conversation_id,
  );

  return (
    <div className="relative h-full p-4">
      <div className="relative z-50 flex h-full">
        <ChatSidebar
          searchConversation={searchConversation}
          setSearchConversation={setSearchConversation}
          conversationsLoading={conversationsLoading}
          filteredConversations={filteredConversations}
          handleConversationSelect={handleConversationSelect}
          selectedConversation={selectedConversation}
          setCurrentConversationChats={setCurrentConversationChats}
        />

        <div className="mx-auto flex size-full flex-col justify-between">
          {selectedConversation ? (
            <>
              <ChatHeader
                selectedConversation={selectedConversation}
                searchChat={searchChat}
                setSearchChat={setSearchChat}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleDeleteConversation={handleDeleteConversation}
              />

              {currentConversationChats && (
                <ChatMessages
                  currentConversationChats={currentConversationChats}
                  searchTerm={searchChat}
                  user={user}
                  answerLoading={answerLoading}
                  moodeeWaitMessage={moodeeWaitMessage}
                  answerFinished={answerFinished}
                  setChatContent={setChatContent}
                  setModifiedChatId={setModifiedChatId}
                  handleDeleteChat={handleDeleteChat}
                  messagesEndRef={messagesEndRef}
                />
              )}
              <ChatInput
                selectedConversation={selectedConversation}
                answerLoading={answerLoading}
                chatContent={chatContent}
                setChatContent={setChatContent}
                modifiedChatId={modifiedChatId}
                setModifiedChatId={setModifiedChatId}
                handleSubmit={handleSubmit}
              />
            </>
          ) : (
            <NewChatForm
              loading={loading}
              focuses={focuses}
              selectedFocus={selectedFocus || ""}
              setSelectedFocus={setSelectedFocus}
              selectedSocialMedia={selectedSocialMedia}
              setSelectedSocialMedia={setSelectedSocialMedia}
              socialMedias={socialMedias}
              handleStartChat={handleStartChat}
            />
          )}
        </div>
      </div>

      <img
        alt="shadow"
        src="chat-shadow.png"
        className="absolute bottom-0 right-0 z-0 hidden blur-[80px]"
      />
    </div>
  );
};

export default ChatPage;
