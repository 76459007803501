import React from "react";
import { Outlet } from "react-router-dom";
import { cn } from "../lib/utils";
import { SidebarNavigation } from "./Sidebar";

const Layout: React.FC = () => {
  return (
    <div
      className={cn(
        "border-border mx-auto flex w-full flex-1 flex-col overflow-hidden border bg-blue-light md:flex-row",
        "h-screen",
      )}
    >
      <SidebarNavigation />
      <main className="border-border size-full overflow-y-scroll rounded-l-5xl border bg-[#FFF]">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
