import { PopoverClose } from "@radix-ui/react-popover";
import {
  ArrowUp,
  ChartColumnBigIcon,
  SquareTerminal,
  XIcon,
} from "lucide-react";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CHATBOT_QUESTIONS } from "../../lib/constants";
import { cn } from "../../lib/utils";
import { Conversation } from "../../types";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Separator } from "../ui/separator";
import { Textarea } from "../ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface ChatInputProps {
  selectedConversation: Conversation;
  answerLoading: boolean;
  chatContent: string;
  setChatContent: (content: string) => void;
  modifiedChatId: string | null;
  setModifiedChatId: (id: string | null) => void;
  handleSubmit: (e: React.FormEvent) => void;
}

const ChatInput = ({
  selectedConversation,
  answerLoading,
  chatContent,
  setChatContent,
  modifiedChatId,
  setModifiedChatId,
  handleSubmit,
}: ChatInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const adjustCursor = (promptLength: number) => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(promptLength, promptLength);
    }
  };

  return (
    <>
      <form
        className={cn(
          (selectedConversation === null || answerLoading) &&
            "pointer-events-none opacity-50",

          "mx-auto flex max-h-64 w-11/12 flex-col rounded-2xl border border-gray-200 p-2.5 shadow-md xl:w-10/12 2xl:w-9/12",
        )}
        onSubmit={handleSubmit}
      >
        {modifiedChatId && (
          <div className="mb-1 flex rounded-lg bg-gray-200 p-1">
            <p className="text-sm">Modification du message</p>
            <button
              onClick={() => {
                setModifiedChatId(null);
                setChatContent("");
              }}
              className="ml-auto"
            >
              <XIcon className="size-5" />
            </button>
          </div>
        )}

        <div className="flex items-start gap-4">
          <Textarea
            ref={textareaRef}
            placeholder="Message à Moodee"
            className="max-h-48 resize-none border-none focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0"
            value={chatContent}
            onChange={(e) => {
              setChatContent(e.target.value);
              adjustHeight();
            }}
            onSubmit={handleSubmit}
          />
          <button
            type="submit"
            className={cn(
              chatContent.length === 0 ? "opacity-0" : "opacity-100",
              "h-fit rounded-xl bg-blue p-1.5 text-white transition-opacity",
            )}
            disabled={
              selectedConversation === null ||
              answerLoading ||
              chatContent.length === 0
            }
          >
            <ArrowUp className="size-5" />
          </button>
        </div>

        <div className="mt-1 flex items-center justify-start gap-4">
          <Popover>
            <PopoverTrigger className="flex items-center gap-2 rounded-lg border border-gray-200 px-4 py-1.5 text-sm hover:bg-gray-200">
              Prompts
              <SquareTerminal className="size-5" />
            </PopoverTrigger>
            <PopoverContent align="start">
              <div className="space-y-1 p-3">
                <h4 className="font-medium leading-none">
                  Suggestions de Moodee
                </h4>
                <p className="text-muted-foreground text-sm">
                  Voici quelques questions que tu pourrais poser à Moodee.
                </p>
              </div>
              <div className="grid">
                <Separator />
                {CHATBOT_QUESTIONS.map((question, index) => (
                  <PopoverClose key={index}>
                    <button
                      className="flex w-full px-6 py-2 text-sm text-dark hover:bg-gray-200"
                      onClick={() => {
                        setChatContent(question.prompt);
                        setTimeout(() => {
                          adjustHeight();
                          adjustCursor(question.prompt.length);
                        }, 0);
                      }}
                    >
                      {question.label}
                    </button>
                    {index !== CHATBOT_QUESTIONS.length - 1 && <Separator />}
                  </PopoverClose>
                ))}
              </div>
            </PopoverContent>
          </Popover>

          <TooltipProvider>
            <Tooltip delayDuration={200}>
              <TooltipTrigger>
                <Link
                  to={`/focus/${selectedConversation.focus_ids[0] || ""}`}
                  state={{ focusId: selectedConversation.focus_ids[0] }}
                  className="flex items-center gap-2 rounded-lg border border-gray-200 px-4 py-1.5 text-sm hover:bg-gray-200"
                >
                  <ChartColumnBigIcon className="size-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                Statistiques de {selectedConversation.focus_names.join(", ")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </form>
      <div className="-mb-4 mt-2 text-center text-[10px] italic text-gray-600">
        Moodee peut faire des erreurs. Envisagez de vérifier les informations
        importantes.
      </div>
    </>
  );
};

export default ChatInput;
