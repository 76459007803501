export const URLS = {
  STATIC_IMAGE: {
    PROD: "https://moodee.app/backend-static/images/",
    DEV: "http://127.0.0.1:8000/backend-static/images/",
    get CURRENT() {
      return process.env.NODE_ENV === "development" ? this.DEV : this.PROD;
    },
  },
  API: {
    PROD: "https://moodee.app/api/v1",
    DEV: "http://127.0.0.1:8000/api/v1",
    get CURRENT() {
      return process.env.NODE_ENV === "development" ? this.DEV : this.PROD;
    },
  },
};

export const CHATBOT_QUESTIONS = [
  {
    label: "Synthèse des données",
    prompt:
      "créé un tableau de synthèse de toutes les datas à ta disposition sur le produit avec les colonnes : source (media), nombre de mentions",
  },
  {
    label: "Forces et faiblesses produit",
    prompt:
      "Créer une synthèse pertinente des 5 points faibles et des 5 points forts les plus cités dans toutes les datas, fait apparaitre les nombre de mentions pour chaque point fort ou point faible. tri les résultat par nombre décroissant de mention.La synthèse doit etre construite en 3 parties : Un résumé, Un tableau pertinents, une conclusion sur l'image renvoyé par lle produit",
  },
  {
    label: "Mentions d'achat",
    prompt:
      "Créer un tableau listant toutes les datas ou il y a mention d'achat du produit dans le commentaire avec les colonnes : Réseau, Auteur, Date (DD/MM/YYYY), Commentaire (la mention d'achat doit être mise en évidence), Lien (url du commentaire, ou url du post si celui du commentaire absent)",
  },
  {
    label: "Graphique sentiment annuel",
    prompt:
      "créer un graphique a barre présentant l'évolution du sentiment client sur l'année 2024 mettant en évidence les avis neutres (gris clair), positifs (vert) et négatifs (rouge), les légendes doivent être écrites en français",
  },
  {
    label: "Critiques positives par média",
    prompt:
      "Créez un tableau structuré listant les 3 critiques les plus positives pour chaque média surveillé, avec les colonnes suivantes :Média : Le média d'où provient la critique.Auteur : L'auteur de la critique.Date : La date de la critique au format JJ/MM/AAAA.Commentaire : Le texte original de la critique.Lien : Lien vers la donnée sourceAssurez-vous que :Les mentions promotionnelles sont exclues.Les doublons sont supprimés.",
  },
  {
    label: "Synthèse Avis Amazon",
    prompt:
      "génère moi une synthèse des avis amazon sur le produit qui va analyser les éléments qui ont évolué positivement ou négativement depuis 1 an, termine par un graphique a barre par mois montrant l'évolution du sentiment client 2024 sur le produit sur l'année 2024",
  },
  {
    label: "Commentaires récents triés",
    prompt:
      "Créez un tableau listant l'ensemble des commentaires relatifs au produit des 3 derniers mois, triés du plus récent au plus ancien. Chaque entrée du tableau doit inclure les colonnes suivantes :Auteur : Nom ou identifiant de l'auteur.Date : Date de publication du commentaire (format : DD/MM/YYYY).Sentiment : Score d'évaluation (échelle de 1 à 5).Commentaire : Texte complet du commentaire, avec un surlignement des parties contenant des critiques négatives.Lien : Lien vers le commentaireAssurez-vous que la présentation soit claire et facilement exploitable pour identifier rapidement les retours négatifs",
  },

  {
    label: "Top 10 mentions négatives",
    prompt:
      "Identifie et crée un tableau avec les 10 mentions les plus négatives concernant le produit. Inclue les colonnes suivantes : Media : la plateforme ou le média où la mention a été publiée. Auteur : l'identité ou pseudonyme de l'auteur. Date : sous le format DD/MM/YYYY. Commentaire : le contenu textuel de la mention. Lien : le lien direct vers la publication. Classe les mentions par ordre décroissant d'intensité du sentiment négatif. Assurez-vous que : Les doublons sont supprimés.",
  },
  {
    label: "Post LinkedIn engageant",
    prompt:
      "Crée un post LinkedIn engageant et humain pour promouvoir le produit. Explique en quoi il se démarque en mettant en avant ses points forts de manière claire et captivante. Ajoute une touche d'humour, une bonne dose de dynamisme et termine avec deux preuves sociales basées sur les meilleurs avis clients. Le ton doit refléter celui d'un community manager super cool, proche de son audience et authentique. Mets l'accent sur l'impact concret que ce produit peut avoir dans la vie ou le travail des utilisateurs.",
  },
  {
    label: "Classement des influenceurs",
    prompt: `
    Objectif : Établir un classement des influenceurs de réseaux sociaux basé sur la fréquence de leurs publications.

Détails de la requête :

Filtrer uniquement les influenceurs actifs sur les réseaux sociaux.
Trier les influenceurs en commençant par ceux qui publient le plus fréquemment.
Générer un tableau de classement avec les colonnes suivantes :
'Auteur' : Nom de l'influenceur.
'Nombre de posts' : Total des publications de l'influenceur.
'Vues' : Moyenne des vues par post.
'Likes' : Moyenne des likes par post.
'Commentaires' : Moyenne des commentaires par post.
'Republications' : Moyenne des republications par post.
'Performance' : Pourcentage de likes par rapport aux vues moyennes par post.
Résultat attendu : Un tableau bien organisé, classant les influenceurs selon le nombre de likes, tout en présentant des données détaillées sur leurs performances en termes de fréquence de publication et d'engagement`,
  },
  {
    label: "Clients mécontents et réponses",
    prompt: `Créez un tableau des clients mécontents identifiés au cours des 6 derniers mois avec les colonnes suivantes :

Média : La plateforme où la critique a été publiée.
Auteur : Le prénom de l'auteur de la critique (sans mentionner le nom complet).
Date du commentaire : La date exacte de publication de la critique au format DD/MM/YYYY
Critique utilisateur : Le contenu détaillé de la critique formulée.
Proposition de réponse du service client : Fournissez un message personnalisé à l'auteur, en répondant directement à ses préoccupations spécifiques. Mentionnez uniquement son prénom, référez-vous à son problème précis, et rédigez la réponse dans la langue utilisée dans sa critique. La réponse doit inclure une approche empathique et proactive.
Lien : Ajoutez un lien vers la critique originale pour consultation.

Exemple de réponse attendue dans la colonne "Proposition de réponse du service client" :

Si un utilisateur nommé "Jean" critique un problème de livraison tardive, la réponse pourrait être :
"Bonjour Jean, merci pour votre retour. Nous sommes désolés d'apprendre que votre commande a pris du retard. Nous comprenons combien cela peut être frustrant. Pour résoudre ce problème, nous avons vérifié votre situation et prenons les mesures nécessaires pour accélérer la livraison. N'hésitez pas à nous contacter en privé pour toute autre question. Bonne journée !"

Assurez-vous que chaque réponse reflète précisément les préoccupations exprimées par l'utilisateur, en utilisant un ton adapté à la situation.`,
  },
];
