import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import { Search, Trash } from "lucide-react";
import { Conversation } from "../../types";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Separator } from "../ui/separator";

interface ChatHeaderProps {
  selectedConversation: Conversation;
  searchChat: string;
  setSearchChat: (value: string) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  handleDeleteConversation: () => void;
}

const ChatHeader = ({
  selectedConversation,
  searchChat,
  setSearchChat,
  isModalOpen,
  setIsModalOpen,
  handleDeleteConversation,
}: ChatHeaderProps) => {
  return (
    <>
      <div className="mx-auto flex h-12 items-center gap-6 text-lg font-semibold lg:gap-8">
        <h1 className="flex h-full items-center overflow-x-auto whitespace-nowrap rounded-xl bg-blue-light px-6 py-3 leading-5">
          {selectedConversation.focus_names.join(", ")}
        </h1>
        <Separator orientation="vertical" />
        <div className="flex h-full items-center gap-2 rounded-xl bg-green-light px-6 py-3">
          {selectedConversation.social_media_formatted_names.map((social) => (
            <div key={social}>
              {social === "tiktok" && <IconBrandTiktok size={24} />}
              {social === "instagram" && <IconBrandInstagram size={24} />}
              {social === "google_news" && <IconBrandGoogle size={24} />}
              {social === "google_maps_reviews" && (
                <IconBrandGoogleMaps size={24} />
              )}
              {social === "linkedin" && <IconBrandLinkedin size={24} />}
              {social === "amazon_reviews" && <IconBrandAmazon size={24} />}
              {social === "facebook" && <IconBrandFacebook size={24} />}
              {social === "x" && <IconBrandX size={24} />}
              {social === "custom" && <IconTool size={24} />}
            </div>
          ))}
        </div>
        <Separator orientation="vertical" />
        <div className="flex h-full w-fit items-center rounded-xl bg-blue-light px-4">
          <Search className="text-dark" />
          <Input
            placeholder="Rechercher..."
            className="w-full rounded-xl border-none text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
            value={searchChat}
            onChange={(e) => setSearchChat(e.target.value)}
          />
        </div>
        <Separator orientation="vertical" />
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogTrigger asChild>
            <Button className="h-full rounded-xl bg-orange">
              <Trash className="size-5" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>Supprimer la conversation</DialogHeader>
            <DialogDescription>Cette action est irréversible</DialogDescription>
            <DialogFooter>
              <Button
                variant="outline"
                hover="jumping"
                className="rounded-lg"
                onClick={() => setIsModalOpen(false)}
              >
                Annuler
              </Button>
              <Button
                className="mx-auto w-fit rounded-lg bg-orange px-8 text-dark"
                hover="jumping"
                onClick={handleDeleteConversation}
              >
                Supprimer
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <Separator
        orientation="horizontal"
        className="mx-auto mt-5 w-11/12 xl:w-10/12 2xl:w-9/12"
      />
    </>
  );
};

export default ChatHeader;
